import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { resetAccount } from 'features/account/accountSlice'
import AccountForm from 'features/account/components/accountForm'
import { Button, Col, DatePicker, Form, Popconfirm, Row, Select, Spin, Table, TableColumnType, Tooltip } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { getProductRebill, getProductRebills, resetProductRebill } from 'features/productRebills/productRebillsSlice'
import ProductRebillForm from 'features/productRebills/components/productRebillForm'
import { createProduct, getProduct, resetProduct } from 'features/product/productSlice'
import dayjs, { Dayjs } from 'dayjs'
import FormItem from 'antd/es/form/FormItem'
import { getOffersListSelect } from 'features/offer/offerSlice'
import { getReport } from 'features/report/reportSlice'
import { Product } from 'features/product/types'
import { DeleteFilled } from '@ant-design/icons'
import { ReportData, ReportDataTable } from 'features/report/types'
type reportFrom = {
  dateFrom: string
  dateTo: string
  offerId: number
  groupBy: 'affId' | 'bin'
}

const ConversionReport = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { offersSelect } = useAppSelector(state => state.offerReducer)
  const { report } = useAppSelector(state => state.reportReducer)
  const [form] = Form.useForm<reportFrom>()
  const [groupBy, setGroupBy] = useState<'affId' | 'bin'>('affId')
  const [tableData, setTableData] = useState<ReportDataTable[]>([])
  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs())
  const [dateTo, setDateTo] = useState<Dayjs>(dayjs())
  useEffect(() => {
    dispatch(getOffersListSelect({}))
  }, [])
  useEffect(() => {
    if (report) {
      const tmpData: ReportDataTable[] = []
      Object.keys(report).forEach(data => {
        tmpData.push({
          groupBy: data,
          clicks: report[data].clicks,
          leads: report[data].leads,
          buyers1: report[data].buyers1,
          declines1: report[data].declines1,
          buyers2: report[data].buyers2,
          declines2: report[data].declines2,
          buyers3: report[data].buyers3,
          declines3: report[data].declines3,
          buyers4: report[data].buyers4,
          declines4: report[data].declines4,
          buyers5: report[data].buyers5,
          declines5: report[data].declines5,
        })
      })
      setTableData(tmpData)
    }
  }, [report])

  const exportToCSV = () => {
    let totalClicks = 0
    let totalLeads = 0
    let totalBuyers1 = 0
    let totalDeclines1 = 0
    let totalBuyers2 = 0
    let totalDeclines2 = 0
    let totalBuyers3 = 0
    let totalDeclines3 = 0
    let totalBuyers4 = 0
    let totalDeclines4 = 0
    let totalBuyers5 = 0
    let totalDeclines5 = 0
    let csv: string = columns.map(column => `"${column.title}"`).join(',') + '\r\n'
    tableData.forEach(data => {
      totalClicks += data.clicks
      totalLeads += data.leads
      totalBuyers1 += data.buyers1
      totalDeclines1 += data.declines1
      totalBuyers2 += data.buyers2
      totalDeclines2 += data.declines2
      totalBuyers3 += data.buyers3
      totalDeclines3 += data.declines3
      totalBuyers4 += data.buyers4
      totalDeclines4 += data.declines4
      totalBuyers5 += data.buyers5
      totalDeclines5 += data.declines5
      let row = ''
      columns.forEach((column, index) => {
        if (index > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          row += `,"${data[column.dataIndex]}"`
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          row += `"${data[column.dataIndex]}"`
        }
      })
      csv += row + '\r\n'
    })
    csv += `"Total","${totalClicks}","${totalLeads}","${totalBuyers1}","${totalDeclines1}","${totalBuyers2}","${totalDeclines2}","${totalBuyers3}","${totalDeclines3}","${totalBuyers4}","${totalDeclines4}","${totalBuyers5}","${totalDeclines5}"`
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    // Create a link to download it
    const pom = document.createElement('a')
    pom.href = url
    pom.setAttribute('download', 'Conversion Report.csv')
    pom.click()
  }

  const generateReport = (values: reportFrom) => {
    dispatch(
      getReport({
        groupBy: values.groupBy,
        offerId: values.offerId,
        dateFrom: dayjs(values.dateFrom).format('YYYY.MM.DD'),
        dateTo: dayjs(values.dateTo).format('YYYY.MM.DD'),
      }),
    )
    setGroupBy(values.groupBy)
  }

  const columns: TableColumnType<ReportDataTable>[] = [
    {
      title: groupBy === 'affId' ? 'Affiliate' : 'Bin',
      dataIndex: 'groupBy',
      key: 'groupBy',
      fixed: 'left',
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
    },
    {
      title: 'Leads',
      dataIndex: 'leads',
      key: 'leads',
    },
    {
      title: 'S1 Buyers',
      dataIndex: 'buyers1',
      key: 'buyers1',
    },
    {
      title: 'S1 Declines',
      dataIndex: 'declines1',
      key: 'declines1',
    },
    {
      title: 'S2 Buyers',
      dataIndex: 'buyers2',
      key: 'buyers2',
    },
    {
      title: 'S2 Declines',
      dataIndex: 'declines2',
      key: 'declines2',
    },
    {
      title: 'S3 Buyers',
      dataIndex: 'buyers3',
      key: 'buyers3',
    },
    {
      title: 'S3 Declines',
      dataIndex: 'declines3',
      key: 'declines3',
    },
    {
      title: 'S4 Buyers',
      dataIndex: 'buyers4',
      key: 'buyers4',
    },
    {
      title: 'S4 Declines',
      dataIndex: 'declines4',
      key: 'declines4',
    },
    {
      title: 'S5 Buyers',
      dataIndex: 'buyers5',
      key: 'buyers5',
    },
    {
      title: 'S5 Declines',
      dataIndex: 'declines5',
      key: 'declines5',
    },
  ]

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Conversion Report</h3>
        <Form onFinish={generateReport} form={form} style={{ width: '100%' }}>
          <Row style={{ width: '100%' }}>
            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Date From'} name={'dateFrom'} initialValue={dayjs()}>
                <DatePicker
                  allowClear={false}
                  onChange={value => {
                    if (value) setDateFrom(value)
                  }}
                  disabledDate={date => date.isAfter(dateTo.add(1, 'day').format('YYYY.MM.DD'))}
                />
              </FormItem>
            </Col>
            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Offer'} name={'offerId'} initialValue={null}>
                <Select
                  options={[
                    { label: 'All', value: null },
                    ...offersSelect.map(offer => ({
                      label: offer.name,
                      value: offer.id,
                    })),
                  ]}
                />
              </FormItem>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Date To'} name={'dateTo'} initialValue={dayjs()}>
                <DatePicker
                  allowClear={false}
                  disabledDate={date => date.isBefore(dateFrom.format('YYYY.MM.DD'))}
                  onChange={value => {
                    if (value) setDateTo(value)
                  }}
                />
              </FormItem>
            </Col>

            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Group By'} name={'groupBy'} initialValue={'affId'}>
                <Select
                  options={[
                    { label: 'Aff Id', value: 'affId' },
                    { label: 'Bin', value: 'bin' },
                  ]}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} style={{ width: '100%' }}>
            <Col>
              <Button type={'primary'} htmlType={'submit'}>
                Generate
              </Button>
            </Col>
            {report && (
              <Col>
                <Button onClick={exportToCSV}>Export to CSV</Button>
              </Col>
            )}
          </Row>
        </Form>
      </Row>

      {report && (
        <Table
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
          dataSource={tableData}
          summary={pageData => {
            let totalClicks = 0
            let totalLeads = 0
            let totalBuyers1 = 0
            let totalDeclines1 = 0
            let totalBuyers2 = 0
            let totalDeclines2 = 0
            let totalBuyers3 = 0
            let totalDeclines3 = 0
            let totalBuyers4 = 0
            let totalDeclines4 = 0
            let totalBuyers5 = 0
            let totalDeclines5 = 0
            pageData.forEach(
              ({
                clicks,
                leads,
                buyers1,
                declines1,
                buyers2,
                declines2,
                buyers3,
                declines3,
                buyers4,
                declines4,
                buyers5,
                declines5,
              }) => {
                totalClicks += clicks
                totalLeads += leads
                totalBuyers1 += buyers1
                totalDeclines1 += declines1
                totalBuyers2 += buyers2
                totalDeclines2 += declines2
                totalBuyers3 += buyers3
                totalDeclines3 += declines3
                totalBuyers4 += buyers4
                totalDeclines4 += declines4
                totalBuyers5 += buyers5
                totalDeclines5 += declines5
              },
            )
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <b>{totalClicks}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <b>{totalLeads}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <b>{totalBuyers1}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <b>{totalDeclines1}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <b>{totalBuyers2}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <b>{totalDeclines2}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <b>{totalBuyers3}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <b>{totalDeclines3}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    <b>{totalBuyers4}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <b>{totalDeclines4}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>
                    <b>{totalBuyers5}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12}>
                    <b>{totalDeclines5}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
      )}
    </>
  )
}
export default ConversionReport
