import React, { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './assets/styles/index.scss'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Header, Footer } from './layouts'
import { ConfigProvider, Row } from 'antd'

import { Provider } from 'react-redux'
import store from './store/store'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getUser } from 'features/user/userSlice'
import Login from 'pages/Login'
import SignUp from 'pages/SignUp'
import Sidebar from 'layouts/sidebar'
import AccountsList from 'pages/AccountsList'
import AccountCreatePage from 'pages/AccountCreatePage'
import AccountEditPage from 'pages/AccountEditPage'
import ProductsList from 'pages/ProductsList'
import ClientsList from 'pages/ClientsList'
import ClientCreatePage from 'pages/ClientCreatePage'
import ClientEditPage from 'pages/ClientEditPage'
import { getClientsListSelect } from 'features/client/clientSlice'
import CampaignsList from 'pages/CampaignsList'
import MidsList from 'pages/MidsList'
import ProductAttemptsList from 'pages/ProductAttemptsList'
import ProductAddonsList from 'pages/ProductAddonsList'
import ProductRebillPage from 'pages/ProductRebillPage'
import LoadBalancerRulesList from 'pages/LoadBalancerRulesList'
import LoadBalancerRuleCreatePage from 'pages/LoadBalancerRuleCreatePage'
import LoadBalancerRuleEditPage from 'pages/LoadBalancerRuleEditPage'
import OffersList from 'pages/OffersList'
import OfferEditPage from 'pages/OfferEditPage'
import OfferCreatePage from 'pages/OfferCreatePage'
import ConversionReport from 'pages/ConversionReport'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch()
  const { name, permission } = useAppSelector(state => state.userReducer)
  const { clientsSelect } = useAppSelector(state => state.clientReducer)
  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])
  useEffect(() => {
    if (permission === 0) {
      dispatch(getClientsListSelect())
    }
  }, [permission])
  if (!name) return null
  if (permission === 0 && !clientsSelect) return null
  return (
    <>
      <Header />
      <Row>
        <Sidebar />
        <main>{children}</main>
      </Row>
      <Footer />
    </>
  )
}
const PrivateRouteAdmin = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch()
  const { name, permission } = useAppSelector(state => state.userReducer)
  const { clientsSelect } = useAppSelector(state => state.clientReducer)
  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])
  useEffect(() => {
    if (permission === 0) {
      dispatch(getClientsListSelect())
    }
  }, [permission])
  if (!name) return null
  if (permission !== 0) return null
  if (permission === 0 && !clientsSelect) return null
  return (
    <>
      <Header />
      <Row>
        <Sidebar />
        <main>{children}</main>
      </Row>
      <Footer />
    </>
  )
}
const PublicNoHeaderRoute = ({ children }: { children: JSX.Element }) => (
  <>
    <main className={'centred-content'}>{children}</main>
    <Footer />
  </>
)
const router = createBrowserRouter(
  [
    {
      path: '/clients',
      element: (
        <PrivateRouteAdmin>
          <ClientsList />
        </PrivateRouteAdmin>
      ),
    },

    {
      path: '/client',
      element: (
        <PrivateRouteAdmin>
          <ClientCreatePage />
        </PrivateRouteAdmin>
      ),
    },
    {
      path: '/client/:id',
      element: (
        <PrivateRouteAdmin>
          <ClientEditPage />
        </PrivateRouteAdmin>
      ),
    },
    {
      path: '/mids',
      element: (
        <PrivateRoute>
          <MidsList />
        </PrivateRoute>
      ),
    },
    {
      path: '/products',
      element: (
        <PrivateRoute>
          <ProductsList />
        </PrivateRoute>
      ),
    },
    {
      path: '/product',
      element: null,
      children: [
        {
          path: ':id',
          element: null,
          children: [
            {
              path: 'attempts',
              element: (
                <PrivateRoute>
                  <ProductAttemptsList />
                </PrivateRoute>
              ),
            },
            {
              path: 'addons',
              element: (
                <PrivateRoute>
                  <ProductAddonsList />
                </PrivateRoute>
              ),
            },
            {
              path: 'rebill',
              element: (
                <PrivateRoute>
                  <ProductRebillPage />
                </PrivateRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '/loadBalancerRules',
      element: (
        <PrivateRoute>
          <LoadBalancerRulesList />
        </PrivateRoute>
      ),
    },
    {
      path: '/loadBalancerRule',
      element: (
        <PrivateRoute>
          <LoadBalancerRuleCreatePage />
        </PrivateRoute>
      ),
    },
    {
      path: '/loadBalancerRule/:id',
      element: (
        <PrivateRouteAdmin>
          <LoadBalancerRuleEditPage />
        </PrivateRouteAdmin>
      ),
    },
    {
      path: '/campaigns',
      element: (
        <PrivateRoute>
          <CampaignsList />
        </PrivateRoute>
      ),
    },
    {
      path: '/accounts',
      element: (
        <PrivateRoute>
          <AccountsList />
        </PrivateRoute>
      ),
    },
    {
      path: '/',
      element: (
        <PrivateRoute>
          <AccountsList />
        </PrivateRoute>
      ),
    },

    {
      path: '/account',
      element: (
        <PrivateRoute>
          <AccountCreatePage />
        </PrivateRoute>
      ),
    },
    {
      path: '/account/:id',
      element: (
        <PrivateRoute>
          <AccountEditPage />
        </PrivateRoute>
      ),
    },

    {
      path: '/offers',
      element: (
        <PrivateRoute>
          <OffersList />
        </PrivateRoute>
      ),
    },

    {
      path: '/offer',
      element: (
        <PrivateRoute>
          <OfferCreatePage />
        </PrivateRoute>
      ),
    },
    {
      path: '/offer/:id',
      element: (
        <PrivateRoute>
          <OfferEditPage />
        </PrivateRoute>
      ),
    },

    {
      path: '/login',
      element: (
        <PublicNoHeaderRoute>
          <Login />
        </PublicNoHeaderRoute>
      ),
    },
    {
      path: '/signup',
      element: (
        <PublicNoHeaderRoute>
          <SignUp />
        </PublicNoHeaderRoute>
      ),
    },
    {
      path: '/conversionReport',
      element: (
        <PrivateRouteAdmin>
          <ConversionReport />
        </PrivateRouteAdmin>
      ),
    },
  ],
  { basename: '/' },
)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '1e6a94',
            fontFamily: 'Roboto, sans-serif',
            fontSize: 16,
          },
          components: {
            Form: {
              itemMarginBottom: 10,
              labelHeight: 20,
              verticalLabelPadding: '0 0 4px',
            },
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
)
